<template>
  <Modal v-model="kjnovaClipperModal" :title="$t('key1000415')" width="900" class-name="kjnovaClipperStyle"
    :mask-closable="false" @on-visible-change="kjnovaClipperChange">
    <Alert type="warning">
      <div class="flex align-items-center">
        <Icon type="ios-alert-outline" :size="24" color="#f90"/>
        <h2 class="font-size-14 font-weight-bold ml8">{{ $t('key1000416') }}</h2>
      </div>
      <template slot="desc">
        {{ $t('key1000417') }}<br/>
        {{ $t('key1000418') }}<br/>
        {{ $t('key1000419') }}
      </template>
    </Alert>
    <div class="flex align-items-center mt20 mb30">
      <div class="flex align-items-center">
        <span>{{ $t('key1000420') }}</span>
        <InputNumber :max="originalWidth" :formatter="value => `${parseInt(value)}`" class="width_80"
          v-model.trim="targetWidth" @on-change="updateCropSize" @on-blur="changeTalg = false"></InputNumber>
        <span class="ml3">px</span>
      </div>
      <div class="flex align-items-center ml50">
        <span>{{ $t('key1000421') }}</span>
        <InputNumber :max="originalHeight" :formatter="value => `${parseInt(value)}`" class="width_80"
          v-model.trim="targetHeight" @on-change="updateCropSize" @on-blur="changeTalg = false"></InputNumber>
        <span class="ml3">px</span>
      </div>
    </div>
    <div class="flex align-items-start justify-content-between">
      <!--裁剪-->
      <div class="kjnovaClipper_box">
        <vueCropper
          ref="cropper"
          :img="options.img"
          :outputSize="options.outputSize"
          :outputType="options.outputType"
          :info="options.info"
          :canScale="options.canScale"
          :autoCrop="options.autoCrop"
          :autoCropWidth="options.autoCropWidth"
          :autoCropHeight="options.autoCropHeight"
          :fixedBox="options.fixedBox"
          :fixed="options.fixed"
          :fixedNumber="options.fixedNumber"
          :canMove="options.canMove"
          :canMoveBox="options.canMoveBox"
          :original="options.original"
          :centerBox="options.centerBox"
          :infoTrue="options.infoTrue"
          :full="options.full"
          :enlarge="options.enlarge"
          :mode="options.mode"
          :maxImgSize="options.maxImgSize"
          :fillColor="options.fillColor"
          @realTime="realTimePreview"
          @imgLoad="imgLoadChange">
        </vueCropper>
      </div>
      <!--预览裁剪后的图片-->
      <div class="cropPreviewImage_box">
        <div class="cropPreviewImage_options">
          <span>{{ $t('key1000422') }}</span>
          <span class="cursor themeColor" @click="$refs.fileInput.click()">{{ $t('key1000423') }}</span>
          <input type="file" ref="fileInput" @change="handleFileChange" :accept="fileTypeList" style="display: none;"/>
        </div>
        <div v-html="cropPreviewImage"></div>
      </div>
    </div>
    <template #footer>
      <Button @click="kjnovaClipperModal=false">{{ $t('key1000097') }}</Button>
      <Button type="primary" @click="kjnovaClipperBtn" :disabled="!options.img">{{ $t('key1000096') }}</Button>
    </template>
  </Modal>
</template>

<script>
import { alias2f085dd487c1465383484787b29fef2c } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/';
import {VueCropper} from 'vue-cropper';
import FileType from 'file-type';
import {endLoading, startLoading} from "@/utils/loading";
import {base64ToBlob, base64ToFile} from "@/utils/common";

export default {
  name: 'kjnovaClipperModal',
  mixins: [Mixin],
  props: {
    // 是否支持多张图片上传
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    let v = this;
    return {
      kjnovaClipperModal: false,
      options: {
        img: '', //裁剪图片的地址
        outputSize: 0.9, // 裁剪生成图片的质量
        outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
        info: false, // 裁剪框的大小信息
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 200, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: false, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
        canMove: false, // 上传图片是否可以移动
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        maxImgSize: 99999,
        full: true, // 是否输出原图比例的截图
        enlarge: 1, // 图片根据截图框输出比例倍数
        mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto，
        fillColor: '#ffffff',
      },
      cropPreviewImage: null,
      uploadFilesUrl: api.post_uploadFiles + '?basePath=yms-supplier-service&&action="uploadimage"',
      fileTypeList: 'image/jpeg, image/jpg, image/png, image/webp, image/bmp',
      maxFileSize: 5, // 单位是M
      targetWidth: null,
      targetHeight: null,
      originalWidth: 0,
      originalHeight: 0,
      initialization: true,
      containerWidth: 400, // 画布的宽度
      containerHeight: 400, // 画布的高度
      changeTalg: false,
    }
  },
  methods: {
    // 初始化数据
    initKjnovaClipperData(uploadImgagesUrl) {
      let v = this;
      startLoading(alias2f085dd487c1465383484787b29fef2c.t('key1000426'));
      v.kjnovaClipperModal = true;
      v.initialization = true;
      console.log('====uploadImgagesUrl===', uploadImgagesUrl)
      v.options.img = uploadImgagesUrl;
    },
    // 加载裁剪图片的回调
    imgLoadChange(data) {
      if (data === 'success') {
        handleLoading();
      } else if (data === 'error') {
        handleLoading();
      }

      function handleLoading() {
        setTimeout(() => {
          endLoading();
        }, 400);
      }
    },

    // 实时预览裁剪的图片
    realTimePreview(data) {
      this.cropPreviewImage = data.html;
      if (!this.changeTalg) {
        this.getCropImgSize(data);
      }
    },
    // 确定裁剪
    async kjnovaClipperBtn() {
      let v = this;
      v.$refs.cropper.getCropData(async data => {
        if (data) {
          // 多选图片
          if (v.multiple) {
            handleUploadImg(data);
          } else {
            const isValid = await checkSize(data);
            if (isValid) {
              handleUploadImg(data);
            }
          }

          async function handleUploadImg(data) {
            let file = await base64ToFile(data, alias2f085dd487c1465383484787b29fef2c.t('key1000427'));
            let url = api.post_uploadFiles + '?basePath=yms-supplier-service&&action="uploadimage"';
            let query = new FormData();
            query.append('files', file);
            v.axios.post(url, query, {loading: true, loadingText: alias2f085dd487c1465383484787b29fef2c.t('key1000212')}).then(response => {
              if (response.data.code === 0) {
                let data = response.data.datas || [];
                if (data.length > 0) {
                  let imgUrl = data[0];
                  v.$emit('updateUploadImg', imgUrl);
                  v.kjnovaClipperModal = false;
                }
              }
            });
          }
        }
      });


      // 校验当前裁剪图片的尺寸大小
      async function checkSize(data) {
        let maxSize = 6000;
        let minSize = 800;
        return new Promise((resolve) => {
          const reader = new FileReader();
          base64ToBlob(data).then(blob => {
            reader.readAsDataURL(blob);
            reader.onload = () => {
              const img = new Image();
              img.src = reader.result;
              img.onload = () => {
                const {naturalWidth, naturalHeight} = img;
                console.log('---naturalWidth----', naturalWidth, '-----naturalHeight-----', naturalHeight);
                if ((naturalWidth >= minSize && naturalHeight >= minSize) && (naturalWidth <= maxSize && naturalHeight <= maxSize)) {
                  resolve(true);
                } else {
                  v.$Message.error(`${alias2f085dd487c1465383484787b29fef2c.t('key1000424')}${minSize}${alias2f085dd487c1465383484787b29fef2c.t('key1000328')}<=${maxSize}`);
                  resolve(false);
                }
              };
            };
          }).catch(error => {
            console.error('Error converting base64 to blob:', error);
            resolve(false);
          });
        });
      }
    },
    // 重新上传图片
    handleFileChange(event) {
      let v = this;
      v.options.img = '';
      startLoading(alias2f085dd487c1465383484787b29fef2c.t('key1000426'));
      const file = event.target.files[0]; // 获取第一个文件
      if (!file) return; // 如果没有选择文件，直接返回

      const maxSize = v.maxFileSize * 1024 * 1024; // 5MB
      let fileSize = file.size;

      const reader = new FileReader();
      reader.onload = async (e) => {
        const buffer = Buffer.from(e.target.result.split(',')[1], 'base64');
        const {mime} = await FileType.fromBuffer(buffer);
        if (fileSize <= maxSize) {
          if (!v.imageFormatList.includes(mime)) {
            endLoading();
            v.$Message.error({
              content: `${alias2f085dd487c1465383484787b29fef2c.t('key1000330')}`,
              duration: 5
            });
            return false;
          } else {
            v.changeTalg = false;
            v.initialization = true;
            v.targetWidth = null;
            v.targetHeight = null;
            v.originalWidth = 0;
            v.originalHeight = 0;
            setTimeout(() => {
              v.options.img = e.target.result;
              v.$refs.cropper.reload();
            }, 500);
          }
        } else {
          endLoading();
          v.$Message.error(`${alias2f085dd487c1465383484787b29fef2c.t('key1000425')}${v.maxFileSize}M`);
          return false;
        }
      };
      reader.readAsDataURL(file);
    },
    // 获取裁剪图片的宽高
    getCropImgSize(data) {
      let v = this;
      let width = data.w;
      let height = data.h;
      // 获取原始图片的宽高数值
      v.originalWidth = parseInt(data.img.width, 10);
      v.originalHeight = parseInt(data.img.height, 10);
      // 比例
      let proportion = 0;
      if (v.originalWidth >= v.originalHeight) {
        proportion = v.originalWidth / v.containerWidth;
      } else {
        proportion = v.originalHeight / v.containerWidth;
      }
      if (!v.changeTalg) {
        v.targetWidth = parseInt(width * proportion);
        v.targetHeight = parseInt(height * proportion);
      }
      if (v.initialization) {
        if (v.originalWidth >= v.originalHeight) {
          v.options.autoCropWidth = v.originalWidth;
          v.options.autoCropHeight = v.originalHeight / proportion;
        } else {
          v.options.autoCropWidth = v.originalWidth / proportion;
          v.options.autoCropHeight = v.originalHeight;
        }
      }
    },
    // 改变裁剪框的宽高
    updateCropSize() {
      let v = this;
      v.changeTalg = true;
      v.initialization = false;
      let proportion = 0;
      if (v.originalWidth >= v.originalHeight) {
        proportion = v.originalWidth / v.containerWidth;
      } else {
        proportion = v.originalHeight / v.containerWidth;
      }
      if (v.targetWidth > 0 && v.targetWidth <= v.originalWidth) {
        let newWidth = v.targetWidth / proportion;
        v.options.autoCropWidth = newWidth;
      }
      if (v.targetHeight > 0 && v.targetHeight <= v.originalHeight) {
        let newHeight = v.targetHeight / proportion;
        v.options.autoCropHeight = newHeight;
      }
      setTimeout(() => {
        v.changeTalg = false;
      }, 1000);
      setTimeout(() => {
        v.$refs.cropper.reload();
      }, 200);
    },
    // 监听弹窗
    kjnovaClipperChange(value) {
      let v = this;
      v.changeTalg = false;
      if (value) {
        v.stopScroll();
      } else {
        v.options.img = '';
        v.initialization = false;
        v.targetWidth = null;
        v.targetHeight = null;
        v.originalWidth = 0;
        v.originalHeight = 0;
        v.removeStopScroll();
        v.$refs['cropper'].refresh(); // 重置裁剪框
      }
    },
  },
  components: {
    VueCropper
  }
};
</script>

<style lang="less">
.kjnovaClipperStyle {
  .kjnovaClipper_box {
    width: 400px;
    height: 400px;
  }

  .cropPreviewImage_box {
    width: 400px;
    height: 400px;
    position: relative;

    .cropPreviewImage_options {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      left: 0;
      position: absolute;
      top: -24px;

    }
  }
}
</style>
